<template>
  <div class="premium-container bg-black-dark text-center rounded">
    <div class="w-100" v-if="!premium_sub.payed">
      <h2>contrate por</h2>

      <p :class="['premium-price', { 'discount-applied': premium_sub.amount != subPrice }]">
        R$ <span>{{ subPrice }}</span>
      </p>

      <div class="premium-price-discount" v-if="premium_sub.amount != subPrice">
        R$ <span>{{ premium_sub.amount }}</span>
      </div>

      <small style="opacity: .4; margin-top: -10px;" class="d-block">
        {{ month_period }}
      </small>
    </div>

    <div class="pt-3">
      <div v-if="premium_sub.payment_method === 'payment_method_card'">
        <p>
          cobranças automáticas em todo o dia {{ premium_sub.first_execution_at.split('-')[2] }}
        </p>
      </div>

      <p style="line-height: 100%;" v-if="false && premium_sub.payed">
        <small style="font-size: .7rem; line-height: 100%;">vamos atualizar o início para o momento que recebermos o pagamento</small>
      </p>
    </div>

    <div class="p-3" v-if="premium_sub.payed">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-shield-check" viewBox="0 0 16 16">
        <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
        <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
      </svg>
      <h3 class="my-3">seu pagamento foi recebido</h3>
      <p>sua assinatura foi ativada, muito obrigado!</p>

      <div>
        <small>validade</small>
        <p class="m-0 text-muted" style="font-size: 14px">
          de {{ premium_sub.plan_starts_at }}
          até {{ premium_sub.plan_ends_at }}
        </p>
      </div>
    </div>

    <div class="p-3" v-else>
      <div class="text-left" v-if="premium_sub.payment_method == 'payment_method_card'">

          <h5 class="text-underlined" style="font-weight: 500; display: inline-block;">estamos processando seu pagamento</h5>

          <p>fica tranquilo que é rapidex.</p>

          <p>
            assim que confirmado, vamos atualizar tudo por aqui<br>e você será avisado :)
          </p>

        <p class="text-muted mt-4">
          <small>
            caso tenha algum problema, fala com a gente
          </small>
        </p>
      </div>

      <div v-else>
        <p>
          <strong>
            para ativar seu premium sub<br>basta pagar o PIX abaixo
          </strong>
        </p>
        <div class="d-block" v-if="premium_sub.pix_qrcode">
          <button class="btn btn-outline-secondary clipboard mb-2" v-clipboard="premium_sub.pix_qrcode">
            clique aqui para copiar
          </button>
        </div>

        <div class="text-center">
          <!-- <img :src="premium_sub.pix_qrcode_base64"/> -->
          <pix-qrcode :url="premium_sub.pix_qrcode" />
        </div>

        <small class="d-block text-muted" style="font-size: .75rem; line-height: 100%; margin-top: 5px;">
          usando seu aplicativo favorito,<br>use a opção scanear QR code
        </small>
        <br>
        <small class="text-muted">
          <strong>após o pagamento sua tela será atualizada sozinha</strong>
        </small>
      </div>
    </div>

    <div class="p-3 mt-2 d-none">
      <small class="text-muted d-block">pagamentos processados por</small>
      <img src="/logo-loco.png" style="width: 120px; height: auto;" />
    </div>
  </div>
</template>

<script>
import PremiumSubService from '@/services/premiumSub.service.js'
import PixQrcode from '@/components/PixQrcode.vue'
export default {
  name: 'Settings',
  data () {
    return {
      tier_prices: {
        tier_basic: '19.99',
        tier_vip: '49.99'
      },
      premium_sub: {}
    }
  },
  props: {
    premiumSubId: {
      type: String,
      default: null
    },
    premiumSubType: {
      type: String,
      default: null
    }
  },
  components: {
    PixQrcode
  },
  computed: {
    premiumId () {
      return this.$route.params.id || this.$route.query.id || this.premiumSubId
    },
    type () {
      return this.$route.params.type || this.$route.query.type || this.premiumSubType
    },
    month_period () {
      return this.premium_sub.months.toString() === '1' ? 'por mês' : `por ${this.premium_sub.months} meses`
    },
    subPrice () {
      const summed = parseFloat(this.tier_prices[this.premium_sub.tier]) * this.premium_sub.months
      const rounded = Math.round(summed * 100) / 100

      return rounded.toString()
    }
  },
  watch: {
    premiumId: {
      immediate: true,
      handler: function () {
        this.initialize()
      }
    }
  },
  metaInfo: {
    title: 'Premium'
  },
  channels: {
    UserNotificationChannel: {
      connected () {
        // console.log('Connected to the user channel')
      },
      received (data) {
        if (data.status === 'payed' && this.premiumId === data.tip_id) {
          // this.initialize()
        } else if (data.status === 'sub_payed' && this.premiumId === data.premium_sub_id) {
          this.initialize()
        }
      }
    }
  },
  methods: {
    async initialize () {
      const subResult = await PremiumSubService.find(this.premiumId)
      this.premium_sub = subResult.data

      console.log(this.premium_sub)

      if (this.premium_sub.payed) this.$store.commit('user/updateCurrentUser')
    }
  },
  mounted () {
    this.initialize()
    this.$cable.subscribe({ channel: 'UserNotificationChannel' })
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
.premium-container {
  h2 {
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 0;
  }
  .premium-price, .premium-price-discount {
    font-weight: 700;
    font-size: 2rem;
    font-family: var(--font-custom);
    margin-bottom: 0;

    color: var(--brand-orange);

    &.discount-applied {
      text-decoration: line-through;
      font-size: 1.2rem;
      color: #666;
      margin-bottom: -10px;
    }
  }

  .valid-coupon {
    border: 1px solid var(--brand-orange);
    background: rgba(255, 255, 255, .1);
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}
</style>
