import Vue from 'vue'

import UserService from '@/services/user.service.js'

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    user: null,
    donationBeforeLogin: null,
    notifications: null
  },
  getters: {
    loggedIn: state => {
      return state.loggedIn
    },
    currentUser: state => {
      return state.user
    },
    avatarUrl: state => {
      return state.user.avatar_url
    },
    coverUrl: state => {
      return state.user.cover_picture_url
    },
    token: state => {
      return state.user && state.user.token ? state.user.token : null
    },
    canChangeUsername: state => {
      return state.loggedIn && state.user.can_change_username ? state.user.can_change_username : false
    },
    cableConnectionUrl: state => {
      try {
        const token = state.user.token.replace('Bearer ', '')
        return `${process.env.VUE_APP_CABLE_URL}cable?stoken=${token}`
      } catch (_) {
        return `${process.env.VUE_APP_CABLE_URL}cable`
      }
    },
    donationBeforeLogin: state => {
      return state.donationBeforeLogin
    },
    notifications: state => {
      try {
        return state.notifications || []
      } catch (_) {
        return []
      }
    },
    notificationsCount: state => {
      try {
        return state.notifications.length || 0
      } catch (_) {
        return 0
      }
    },
    notificationsUnreadCount: state => {
      try {
        return state.notifications.filter(n => !n.read).length || 0
      } catch (_) {
        return 0
      }
    }
  },
  actions: {
    login ({ commit }, payload) {
      commit('loginSuccess', payload)
    },
    updateCurrentUser ({ commit }) {
      commit('updateCurrentUser')
    },
    updateNotifications ({ commit }) {
      commit('updateNotifications')
    },
    updateAvatar ({ commit }, payload) {
      commit('updateAvatar', payload)
    },
    updateCover ({ commit }, payload) {
      commit('updateCover', payload)
    },
    updateSound ({ commit }, payload) {
      commit('updateSound', payload)
    },
    updateGif ({ commit }, payload) {
      commit('updateGif', payload)
    },
    saveDonationBeforeLogin ({ commit }, payload) {
      commit('saveDonationBeforeLogin', payload)
    },
    clearDonationBeforeLogin ({ commit }) {
      commit('clearDonationBeforeLogin')
    },
    logout ({ commit }) {
      commit('logout')
    }
  },
  mutations: {
    loginSuccess (state, user) {
      state.loggedIn = true
      state.user = user.data

      try {
        this.$posthog.identify(state.user.id, { email: state.user.email, premium: state.user.premium })
      } catch (_) {
      }

      if (user.headers.authorization) {
        state.user.token = user.headers.authorization
      }
    },
    saveDonationBeforeLogin (state, params) {
      state.donationBeforeLogin = params
    },
    clearDonationBeforeLogin (state, params) {
      state.donationBeforeLogin = null
    },
    logout (state) {
      state.loggedIn = false
      state.user = null
    },
    async updateCurrentUser (state) {
      const currentToken = state.user.token
      const userResult = await UserService.account()

      Vue.set(state.user, 'demoplot', userResult.data)

      state.user = userResult.data
      try {
        this.$posthog.identify(state.user.id)
        this.$posthog.identify(state.user.id, { email: state.user.email, premium: state.user.premium })
      } catch (_) {
      }
      state.user.token = currentToken
    },
    async updateNotifications (state) {
      const result = await UserService.notifications()

      state.notifications = result.data
    },
    updateAvatar (state, params) {
      state.user.avatar_url = params
    },
    updateCover (state, params) {
      state.user.cover_picture_url = params
      state.user.notification_alert_cover_url = params
    },
    updateSound (state, params) {
      state.user.overlay_sound_url = params
      state.user.notification_alert_sound_url = params
    },
    updateGif (state, params) {
      state.user.donate_gif = params
    }
  }
}
