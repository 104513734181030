<template>
  <div class="pt-4 links-container">
    <router-link v-if="$route.name !== 'Login'" to="/login">Entrar</router-link>
    <router-link v-if="$route.name !== 'Register'" to="/register">Crie sua conta</router-link>
    <router-link v-if="$route.name !== 'ForgotPassword'" to="/forgot-password">Esqueceu sua senha?</router-link>
    <!-- <router-link v-if="$route.name !== 'ResendConfirmation'" to="/resend-confirmation">Não recebeu email de confirmação?</router-link> -->
    <!-- <router-link v-if="$route.name !== 'ResendUnlock'" to="/resend-unlock">Não recebeu email para desbloquear?</router-link> -->
</div>

</template>

<script>
export default {
  name: 'FooterLinks'
}
</script>

<style lang="scss" scoped>
.links-container {
  margin: 2rem 0 4rem;

  display: flex;
  flex-direction: column;
  a {
    display: inline-block;
    padding: 0.4rem 1rem;
    border: 1px solid #fff;
    border-radius: 25px;
    width: fit-content;
    margin: 0.4rem 0;
    color: white;
  }
}
</style>
