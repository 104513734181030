<template>
  <div class="pix-qrcode-container">
    <vue-qrcode :color="colorInfo" :errorCorrectionLevel="'H'" :value="currentUrl" :width="240" :maskPattern="3" :margin="2" />
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
export default {
  name: 'PixQrcode',
  props: {
    url: String,
    isTransparent: Boolean,
    lightColor: String,
    darkColor: String
  },
  components: {
    VueQrcode
  },
  methods: {
  },
  computed: {
    colorInfo () {
      return {
        dark: `#${this.darkColor || '000000'}`,
        light: this.isTransparent ? '#0000' : `#${this.lightColor || 'ffffff'}`
      }
    },

    currentUrl () {
      return this.url || ''
    }
  }
}
</script>

<style scoped lang="scss">
.pix-qrcode-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
