<template>
  <b-navbar toggleable="lg" fixed type="light" class="navbar-custom">
    <b-navbar-brand to="/">
      <LogoWhite />
    </b-navbar-brand>

      <div class="container px-0">
        <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-if="currentUser">
            <b-nav-item to="/dashboard">Dashboard</b-nav-item>
            <!-- <b-nav-item to="/settings">Configurações</b-nav-item> -->
          </b-navbar-nav>

          <b-navbar-nav v-if="!currentUser">
            <!-- <b-nav-item to="/">Inicial</b-nav-item>
            <b-nav-item to="/pages/taxas">Preço</b-nav-item>
            <b-nav-item to="/pages/faq">Perguntas Frequentes</b-nav-item> -->

            <b-nav-item v-if="$route.name !== 'Login'" to="/login">Entrar</b-nav-item>
            <b-nav-item v-else to="/register">Criar conta</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto" v-if="currentUser">
            <b-nav-item to="/dashboard">{{ currentUser.email }}</b-nav-item>
            <b-nav-item-dropdown class="text-sm" right v-if="false">
              <template #button-content>
                <em>{{ currentUser.email }}</em>
              </template>
              <b-dropdown-item to="/settings/account">Meu cadastro</b-dropdown-item>
              <b-dropdown-item to="/portal">Minhas doações</b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item @click.stop.prevent="logout">Sair</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import UserService from '@/services/user.service'
import LogoWhite from '@/components/LogoWhite.vue'

export default {
  name: 'HeaderSmall',
  components: {
    LogoWhite
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    }
  },
  methods: {
    async logout () {
      await UserService.logout()
      this.$store.commit('user/logout')
      this.$router.push('/').catch(() => { })
    }
  }
}
</script>

<style lang="scss" scope>
  .bg-darkzao {
    background-color: rgb(10, 10, 10) !important;
  }

  .navbar {
    position: fixed !important;
    left: 0px;
    top: 0px;
    width: 100vw;

    z-index: 999;

    .navbar-brand {
      position: absolute;
      top: 5px;
      left: 5px;

      justify-content: center;
      unicode-bidi: center;
      .logo {
        h3 {
          display: none;
        }
      }
    }
  }
</style>
