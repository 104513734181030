<template>
  <b-modal
    v-model="show"
    centered
    hide-footer
    hide-header
    size="xl"
    id="modal-sign-in"
    class="modal-sign-in p-0"
    scrollable
  >
    <button class="button-close position-absolute close" @click="show = false">×</button>
    <div class="row m-0 mb-md-1">
      <div class="col-12 col-lg-6 p-3 p-md-5">
        <transition appear appear-class="fade" name="fade" mode="out-in">
          <component v-bind:is="view" @createPremiumSub="createPremiumSubHandler" :premiumSubId="premiumSubId" :balance="balance"/>
        </transition>
      </div>

      <div class="col-12 col-lg-6 p-3 p-md-5" style="background: rgba(125, 125, 125, 0.07)">
        <h5 class="mb-3 text-muted">A Tipa é feita por pessoas como você</h5>
        <p class="mb-3 text-muted">Grandes ideias se constróem juntos! Apoiar a Tipa nos ajuda a manter as <strong>menores taxas sempre</strong>, além de permitir que a gente desenvolva novos recursos para sua live faturar cada vez mais.</p>
        <!-- <h5 class="mb-3 text-muted">Seu Tipa excedeu o limite de uso</h5>
        <h6 class="mb-3 text-muted">Se torne premium nós ajudando e liberando todos os recursos ilimitados</h6> -->
        <p class="mb-3 text-muted">Além de nos apoiar, você desbloqueia tuuudo isso:</p>
        <ul class="list-style mt-2 px-4">
          <li class="list-item">
            <p class="mb-1">Escolha seu username (tipa.ai/seunome)</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Destaque do canal na Tipa</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Tipa Subs com Tiers ilimitadas</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Defina o valor mínimo para doações</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Customize a página de apoio</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Realize saques mensais ilimitados</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Contato direto com a galera da Tipa</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="row m-0 mb-md-1">
      <div class="col-12 col-lg-6 px-3 px-md-5 py-2 d-flex align-items-center">
        <small class="m-0 text-muted">Ficou com alguma dúvida?</small>
        <small>
          <a class="m-0 ml-1" href="https://bit.ly/tipadiscord">Estamos no discord esperando vc <span style="color: var(--brand-orange)">&lt;3</span></a>
        </small>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PageCreate from '@/components/CardPremiumSubCreate/index.vue'
import PageShow from '@/components/CardPremiumSubShow/index.vue'

export default {
  name: 'ModalPremium',
  data () {
    return {
      view: 'page-create',
      form: {
        email: '',
        password: ''
      },
      premiumSubId: null
    }
  },
  components: {
    'page-create': PageCreate,
    'page-show': PageShow
    // 'payment-wallet': PaymentWallet
  },
  watch: {
    premiumSubId: {
      immediate: true,
      handler: function async (newVal) {
        this.view = newVal ? 'page-show' : 'page-create'
      }
    }
  },
  computed: {
    show: {
      cache: false,
      get: function () {
        // console.log(this.$store.state.modal.balance)
        return this.$store.state.modal.show === 'modal-premium'
      },
      set: function () {
        this.$store.commit('modal/closeModal')
      }
    },
    balance () {
      return this.$store.state.modal.balance
    },
    currentUser () {
      return this.$store.getters['user/currentUser']
    }
  },
  mounted () {
    // console.log(this.$store.state.modal.balance_raw)
    // this.saveEvent('open')
    // saveEvent
  },
  methods: {
    openModalSignUp () {
      this.$router.push('/premium_subs')
      this.show = false
    },
    createPremiumSubHandler (id) {
      this.premiumSubId = id
    },
    async saveEvent (event = '') {
      try {
        this.$ahoy.track('modal_premium', { user_reference: this.currentUser.id, event: event })
      } catch (_) {
        console.log('_', _)
      }
    }
  }
}
</script>

<style lang="scss">
  .card-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  #modal-sign-in {
    padding-left: 0px !important;
    .button-close {
      z-index: 999;
      position: absolute;
      right: 10px;
      top: 10px;

      color: var(--text-color)
    }
    h5, h6 {
      font-weight: 400
    }

    .modal-header {
      border-bottom: none;
    }
    .modal-body {
      overflow-y: scroll;
      padding: 0;
    }

    .modal-body::-webkit-scrollbar {
      display: none;
    }

    .modal-content {
      border: none;
    }
  }
</style>
