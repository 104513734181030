<template>
  <div class="premium-container bg-black-dark rounded">
    <h5 class="mb-4 text-muted">apoie a tipa e seja premium 🧡</h5>

    <h2>por apenas</h2>

    <p :class="['premium-price', { 'discount-applied': hasDiscount }]">
      R$ <span>{{ subPrice }}</span>
    </p>

    <div class="premium-price-discount" v-if="hasDiscount">
      R$ <span>{{ totalPrice }}</span>
    </div>

    <small style="opacity: .4; margin-top: -10px;" class="d-block">
      {{month_period}}
    </small>

    <b-form-group class="my-4" style="">
      <b-form-radio-group
        v-model="form.months"
        :options="optionsMonths"
        button-variant="outline-secondary"
      />
    </b-form-group>

    <b-form-checkbox
      v-model="form.hasCoupon"
      :value="true"
      :unchecked-value="false"
      class="my-2"
      size="sm"
    >
      <small>
        Você tem um <span>cupom de desconto</span>?
      </small>
    </b-form-checkbox>

    <b-form @submit.stop.prevent="validateCoupon" v-if="form.hasCoupon" class="mb-4">
      <b-form-group :description="couponDescriptionText">
        <b-input-group>
          <b-form-input :class="[{'valid-coupon': hasDiscount }]" :disabled="hasDiscount" size="sm" v-model="form.coupon" placeholder="informe seu cupom" type="text"></b-form-input>
          <b-input-group-append>
            <b-button v-if="!hasDiscount" @click.stop.prevent="validateCoupon" variant="outline-primary" size="sm">validar</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>

    <small class="mt-4 mb-4 text-muted">como prefere pagar</small>

    <div class="row">
      <div class="col-4">
        <b-button block @click="createPremiumSub('payment_method_wallet', true)" class="btn-pay wallet" variant="primary" :disabled="parseFloat(balance) < totalPriceRaw">
          <icon-tipa fill_color="#fff" />
          <strong class="d-block">saldo da tipa</strong>
          <small class="d-block auto-renew">renovação automática</small>
        </b-button>
      </div>

      <div class="col-4">
        <b-button block @click="createPremiumSub('payment_method_wallet')" class="btn-pay wallet" v-b-tooltip.hover title="vamos te lembrar por email no final do período" :disabled="parseFloat(balance) < totalPriceRaw">
          <icon-tipa fill_color="#fff" />
          <strong class="d-block">saldo da tipa</strong>
        </b-button>
      </div>
      <div class="col-4">
        <b-button
          @click="createPremiumSub('payment_method_pix')"
          block
          class="btn-pay pix"
          :disabled="isPremiumLoading"
        >
          <icon-qr-code/>
          <strong class="d-block">pix</strong>
        </b-button>
      </div>
    </div>

    <b-button block v-if="!hasCreditCardSubscriptionActive" class="text-muted mt-4" v-b-toggle.sidebarAddress variant="text">
      <!-- <b-spinner small type="grow" variant="light"></b-spinner> -->
      <small>cartão de credito</small>
    </b-button>

    <b-sidebar ref="sidebarAddress" id="sidebarAddress" class="my-2" title="Checkout" backdrop right
      :no-close-on-backdrop="cardSubLoading"
      :no-close-on-esc="cardSubLoading"
      :no-header-close="cardSubLoading"
    >
      <div class="p-3" v-if="addressNeeded">
        <p>
          Precisamos de mais algumas informações suas, é rapidinho.
        </p>

        <p class="">
          Fica susse, que elas estão seguras e são usadas apenas para processarmos o seu pedido.
        </p>

        <b-overlay :show="addressLoading" rounded="sm">

          <b-form @submit.stop.prevent="onAddressSubmit">
            <div class="row">
              <div class="col-12">
                <b-form-group label="CEP" :disabled="zipcodeLoading">
                  <b-form-input type="number" maxlength="8" v-model="form_address.address_zipcode" />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group label="Endereço" :disabled="zipcodeLoading">
                  <b-form-input type="text" maxlength="120" v-model="form_address.address_street" />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <b-form-group label="Número">
                  <b-form-input ref="numberField" maxlength="50" type="text" v-model="form_address.address_number" />
                </b-form-group>
              </div>
              <div class="col-8">
                <b-form-group label="Complemento">
                  <b-form-input type="text" maxlength="100" v-model="form_address.address_complement" />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-8">
                <b-form-group label="Cidade" :disabled="zipcodeLoading">
                  <b-form-input type="text" maxlength="100" v-model="form_address.address_city" />
                </b-form-group>
              </div>

              <div class="col-4">
                <b-form-group label="Estado" :disabled="zipcodeLoading">
                  <b-form-input type="text" maxlength="2" v-model="form_address.address_state" />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group label="Data de Nascimento">
                  <b-form-input type="date" maxlength="10" v-model="form_address.birthdate" />
                </b-form-group>
              </div>
            </div>

            <b-button block type="submit" variant="info">Salvar e prosseguir</b-button>
          </b-form>
        </b-overlay>
      </div>

      <div class="p-3" v-else>
        <b-overlay :show="cardSubLoading" rounded="sm">
          <p>
            Agora, você precisa informar os dados de seu cartão.
          </p>
          <p>
            Fica tranquilo, você está em segurança :)
          </p>

          <b-form @submit.stop.prevent="onCardSubmit">

            <div class="row">
              <div class="col-12">
                <b-form-group :data-error="(cardErrors.cardNumber)?true:false" label="Número do Cartão" :description="cardErrors && cardErrors.cardNumber ? cardErrors.cardNumber : null">
                  <b-form-input data-card-brand v-cardformat:formatCardNumber v-model="form_card.number"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-4" :data-error="(cardErrors.cardCvv)?true:false" >
                <b-form-group label="Código" :description="cardErrors && cardErrors.cardCvv ? cardErrors.cardCvv : null">
                  <b-form-input ref="cvvField" v-cardformat:formatCardCVC v-model="form_card.cvv"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-8" :data-error="(cardErrors.cardExpiry)?true:false" >
                <b-form-group label="Vencimento" :description="cardErrors && cardErrors.cardExpiry ? cardErrors.cardExpiry : null">
                  <div class="row no-gutters">
                    <div class="col-5">
                      <b-form-select v-model="form_card.expiration_month">
                        <b-form-select-option
                          v-for="item in ['01','02','03','04','05','06','07','08','09','10','11','12']"
                          :key="item"
                          :value="item"
                        >
                          {{item}}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                    <div class="col-7">
                      <b-form-select v-model="form_card.expiration_year">
                        <b-form-select-option
                          v-for="item in ['2021','2022','2023','2024','2025','2026','2027','2028','2029','2030']"
                          :key="item"
                          :value="item"
                        >
                          {{item}}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>

            <b-button block type="submit" variant="success">Pagar Premium</b-button>
          </b-form>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import PremiumSubService from '@/services/premiumSub.service.js'
export default {
  name: 'PremiumSubs',
  data () {
    return {
      form: {
        hasCoupon: false,
        coupon: '',
        tier: 'tier_basic',
        months: 1
      },
      tier_prices: {
        tier_basic: '19.99',
        tier_vip: '49.99'
      },
      form_address: {
        address_street: '',
        address_neighborhood: '',
        address_city: '',
        address_state: '',
        address_zipcode: '',
        birthdate: ''
      },
      form_card: {
        brand: 'visa', // bandeira do cartão
        number: '', // número do cartão
        cvv: '', // código de segurança
        expiration_month: '', // mês de vencimento
        expiration_year: '' // ano de vencimento
      },
      $cardFormat: null,
      cardErrors: {},
      cardBrand: null,
      zipcodeLoading: false,
      cardSubLoading: false,
      addressLoading: false,
      coupon: {
        discount_amount: 0.0,
        valid: false,
        invalid: false
      },
      gn: {},
      isPremiumLoading: false,
      optionsMonths: [
        { text: '1 mês', value: '1' },
        { text: '3 meses', value: '3' },
        { text: '6 meses', value: '6' },
        { text: '12 meses', value: '12' }
      ]
    }
  },
  props: {
    balance: {
      type: String,
      default: '0'
    }
  },
  computed: {
    readyForStream () {
      return this.currentUser.ready_for_stream
    },
    donateUrl () {
      return `https://tipa.ai/${this.currentUser.username}`
    },
    isPremium () {
      return this.currentUser.premium
    },
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    subPrice () {
      const summed = parseFloat(this.tier_prices[this.form.tier]) * this.form.months
      const rounded = Math.round(summed * 100) / 100

      return rounded.toString().replace('.', ',')
    },
    hasDiscount () {
      return this.coupon.discount_amount > 0.0
    },
    discountPrice () {
      return this.coupon.discount_amount.toFixed(2).toString().replace('.', ',')
    },
    totalPrice () {
      return (this.tier_prices[this.form.tier] * this.form.months - this.coupon.discount_amount).toFixed(2).toString().replace('.', ',')
    },
    totalPriceRaw () {
      return (this.tier_prices[this.form.tier] * this.form.months - this.coupon.discount_amount)
    },
    couponDescriptionText () {
      return this.coupon.invalid ? 'cupom inválido :(' : null
    },
    addressNeeded () {
      return !(this.currentUser.address_zipcode && this.currentUser.address_zipcode !== '') && !(this.currentUser.birthdate && this.currentUser.birthdate !== '')
    },
    hasCreditCardSubscriptionActive () {
      try {
        return (this.premiumSubs.find(el => el.payment_method === 'payment_method_card' && el.payed))
      } catch (_) {
        return false
      }
    },
    monthsLabel () {
      return `por ${this.form.months} ${this.form.months.toString() === '1' ? 'mês' : 'meses'}`
    },
    month_period () {
      return this.form.months.toString() === '1' ? 'por mês' : `por ${this.form.months} meses`
    }
  },
  watch: {
    'form.hasCoupon': function (newVal) {
      if (!newVal) {
        this.coupon.discount_amount = 0.0
        this.form.coupon = ''
      }
    },
    cardBrand (newVal) {
      this.cardBrand = newVal
      this.form_card.brand = newVal
    },
    'form.coupon': function () {
      this.coupon.invalid = false
      this.coupon.valid = false
    },
    'form_address.address_zipcode': function (newVal) {
      if (newVal && newVal.length === 8) {
        this.lookup()
      }
      this.form_address.address_zipcode = newVal.replace(/\D/g, '')
    }
  },
  methods: {
    async createPremiumSub (paymentMethod, autoRenew = false) {
      this.isPremiumLoading = true

      this.saveEvent('payment_method_clicked_' + paymentMethod + '_' + autoRenew)

      try {
        const resultSub = await PremiumSubService.createNew({
          discount_code: this.form.coupon,
          tier: this.form.tier,
          months: this.form.months,
          payment_method: paymentMethod,
          auto_renew: autoRenew
        })

        this.isPremiumLoading = false
        this.$emit('createPremiumSub', resultSub.data.id)
      } catch (error) {
        if (error.error) {
          this.$toasted.error(error.error)
        }
        this.isPremiumLoading = false
      }
    },

    async validateCoupon () {
      try {
        const resultCoupon = await PremiumSubService.validateCoupon(this.form.coupon)
        this.coupon.valid = resultCoupon.data.valid
        this.coupon.invalid = resultCoupon.data.invalid
        this.coupon.discount_amount = resultCoupon.data.discount_amount // 3.9
      } catch (_) {
        this.coupon.invalid = true
      }
    },

    async lookup () {
      try {
        this.zipcodeLoading = true
        const resultZip = await PremiumSubService.zipLookup(this.form_address.address_zipcode)

        // this.form_address.address_street = resultZip.data.address
        // this.form_address.address_neighborhood = resultZip.data.district
        // this.form_address.address_city = resultZip.data.city
        // this.form_address.address_state = resultZip.data.state
        this.form_address.address_street = resultZip.data.logradouro
        this.form_address.address_neighborhood = resultZip.data.bairro
        this.form_address.address_city = resultZip.data.localidade
        this.form_address.address_state = resultZip.data.uf

        this.zipcodeLoading = false

        this.$refs.numberField.focus()
      } catch (_) {
        this.$toasted.error('Não encontramos o CEP informado')
        this.zipcodeLoading = false
      }
    },

    async onAddressSubmit () {
      try {
        // const resultAddress =
        this.addressLoading = true
        await PremiumSubService.updateAddress({ user: this.form_address })

        await this.$store.commit('user/updateCurrentUser')
      } catch (_) {
        this.addressLoading = false
        this.$toasted.error(_.error)
      }
    },

    async onCardSubmit () {
      try {
        this.cardSubLoading = true
        if (!await this.validateCard()) {
          this.cardSubLoading = false
          return false
        }
        const tokenize = await this.tokenizeCard()

        if (tokenize.code === 200) {
          const cardResult = await PremiumSubService.createSubCard(tokenize.data.card_mask, tokenize.data.payment_token)

          try {
            this.$refs.sidebarAddress.hide()
          } catch (_) {}

          this.$toasted.info('estamos processando o seu pagamento')

          this.$emit('createPremiumSub', cardResult.data.id)
        } else {
          this.cardSubLoading = false
        }
      } catch (_) {
        this.$toasted.error(_.error)
        this.cardSubLoading = false
      }
    },

    async validateCard () {
      // init
      this.cardErrors = {}

      // validate card number
      if (!this.$cardFormat.validateCardNumber(this.form_card.number)) {
        this.cardErrors.cardNumber = 'por conferir? o número parece incorreto'
      }

      // validate card expiry
      if (!this.$cardFormat.validateCardExpiry(`${this.form_card.expiration_month}/${this.form_card.expiration_year}`)) {
        this.cardErrors.cardExpiry = 'confere? a data parece estar inválida'
      }

      // validate card CVC
      if (!this.$cardFormat.validateCardCVC(this.form_card.cvv)) {
        this.cardErrors.cardCvv = 'esse campo parece incorreto'
      }

      return JSON.stringify(this.cardErrors).length === 2
    },

    async tokenizeCard () {
      const self = this
      return new Promise((resolve, reject) => {
        try {
          const s = document.createElement('script')
          s.type = 'text/javascript'
          const v = parseInt(Math.random() * 1000000)
          if (process.env.NODE_ENV === 'development') {
            s.src = 'https://sandbox.gerencianet.com.br/v1/cdn/c1179d71f2211c700cdf15a45af0e99b/' + v
          } else {
            s.src = 'https://api.gerencianet.com.br/v1/cdn/c1179d71f2211c700cdf15a45af0e99b/' + v
          }
          s.async = false
          s.id = 'c1179d71f2211c700cdf15a45af0e99b'
          if (!document.getElementById('c1179d71f2211c700cdf15a45af0e99b')) {
            document.getElementsByTagName('head')[0].appendChild(s)
          };

          window.$gn = {
            validForm: true,
            processed: false,
            done: {},
            ready: function (fn) {
              window.$gn.done = fn
            }
          }

          window.$gn.ready(function (checkout) {
            const callback = function (error, response) {
              if (error) {
                // Trata o erro ocorrido
                console.error(error)
                self.$toasted.error(JSON.stringify(error))
                return reject(error)
              } else {
                // Trata a resposta
                // console.log(response)
                return resolve(response)
              }
            }

            checkout.getPaymentToken(self.form_card, callback)
          })
        } catch (_) {
          reject(_)
        }
      })
    },
    async saveEvent (event = '') {
      try {
        this.$ahoy.track('modal_premium', { user_reference: this.currentUser.id, event: event })
      } catch (_) {
        console.log('_', _)
      }
    }
  },
  mounted () {
    try {
      this.$cardFormat = this.prototype.$cardFormat
    } catch (_) {}
  }
}
</script>

<style lang="scss" scoped>
.premium-container {
  .btn-group-toggle {
    display: flex;

    label {
      flex: 1;
    }
  }

  .auto-renew {
    font-size: 65%;
    color: rgba(255, 255, 255, .5);
  }

  .btn-pay {
    height: 100px;

    svg {
      margin-bottom: 0.5rem;
    }
  }

  h2 {
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 0;
  }
  .premium-price, .premium-price-discount {
    font-weight: 700;
    font-size: 2rem;
    font-family: var(--font-custom);
    margin-bottom: 0;

    color: var(--brand-orange);

    &.discount-applied {
      text-decoration: line-through;
      font-size: 1.2rem;
      color: #666;
      margin-bottom: -10px;
    }
  }

  .valid-coupon {
    border: 1px solid var(--brand-orange);
    background: rgba(255, 255, 255, .1);
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}
</style>
