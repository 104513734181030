import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ActionCableVue from 'actioncable-vue'
import VueMeta from 'vue-meta'
import { SpinnerPlugin, FormFilePlugin, VBTooltipPlugin, ProgressPlugin, TabsPlugin, InputGroupPlugin, BadgePlugin, SkeletonPlugin, FormRadioPlugin, FormCheckboxPlugin, SidebarPlugin, OverlayPlugin, FormSelectPlugin, PaginationPlugin, AlertPlugin, FormPlugin, AvatarPlugin, FormGroupPlugin, FormInputPlugin, FormTextareaPlugin, ButtonPlugin, ImagePlugin, NavbarPlugin, LayoutPlugin, ModalPlugin, CardPlugin, DropdownPlugin, TablePlugin, IconsPlugin } from 'bootstrap-vue'

import './stylesheets/main.scss'
import './stylesheets/icons'

import DefaultLayout from './layouts/Default.vue'
import AuthLayout from './layouts/Auth.vue'
import MarketingLayout from './layouts/Marketing.vue'
import OverlayLayout from './layouts/Overlay.vue'
import NextLayout from './layouts/Next.vue'
import MoneySpinner from 'v-money-spinner'

import VueClipboard from 'vue-clipboard2'

import Toasted from 'vue-toasted'

import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

import ahoy from 'ahoy.js'

import VueCardFormat from 'vue-credit-card-validation'

import posthog from 'posthog-js'

import moment from 'moment'
import 'moment/locale/pt-br'

import VueSimpleMarkdown from 'vue-simple-markdown'

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'vue-simple-markdown/dist/vue-simple-markdown.css'

import i18n from './i18n'

Vue.use(VueSimpleMarkdown)

Vue.use(VueCardFormat)
posthog.init('phc_stl8QnWSFJIO2WZeyBlM5dVpPJU2lsNvls14Q5Yfe6o', { api_host: 'https://app.posthog.com' })

ahoy.configure({
  visitsUrl: process.env.VUE_APP_API_URL + 'api/s/visits.json',
  eventsUrl: process.env.VUE_APP_API_URL + 'api/s/events.json',
  visitDuration: 1 * 15, // 15 minutes
  visitorDuration: 2 * 365 * 24 * 60, // 2 years
  // cookies: false,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  },
  startOnReady: true, // desativado por conta do casimiro
  trackVisits: true // desativado por conta do casimiro
})
ahoy.trackView()
// ahoy.trackClicks('input[type=submit]')
// a, button,

Object.defineProperty(Vue.prototype, '$ahoy', { value: ahoy })
Object.defineProperty(Vue.prototype, '$posthog', { value: posthog })

// ahoy.trackSubmits('form')
// ahoy.trackChanges('input, textarea, select')
// visitParams: {
//   siteSlug: window.PerfilSiteSlug
// },
// If you use headers for authentication, pass them with:
// ahoy.configure({headers: {"Authorization": "Bearer ..."}});

Vue.use(Toasted, {
  router,
  keepOnHover: true,
  dontClose: false,
  duration: 5000
})

Vue.use(require('vue-moment'), {
  moment
})

Vue.filter('toCurrency', function (value) {
  try {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
    return formatter.format(value)
  } catch (_) {
    return value
  }
})

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('marketing-layout', MarketingLayout)
Vue.component('overlay-layout', OverlayLayout)
Vue.component('next-layout', NextLayout)

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: store.getters['user/cableConnectionUrl'], // or function which returns a string with your JWT appended to your server URL as a query parameter
  connectImmediately: false,
  store
})

// Sentry.init({
//   Vue,
//   // dsn: 'https://25527b1aab9847cba03d0bfdf23d7f7b@o1071366.ingest.sentry.io/6068937',
//   // dsn: 'https://28a22a2558de46df92ae7603a0fc64fa@mon.locomotiva.info/6',
//   dsn: 'https://1aa524fcca5b4159f081d0b48cfe482e@mon.loco.ltd/6',
//   integrations: [new Integrations.BrowserTracing({
//     routingInstrumentation: Sentry.vueRouterInstrumentation(router)
//   })],
//   // tracingOrigins: ['tipa.ai'],
//   // We recommend adjusting this value in production, or using tracesSampler
//   tracesSampleRate: 0.55,
//   tracingOptions: {
//     trackComponents: true,
//     tracingOrigins: ['tipa.ai']
//   }
// })

Sentry.init({
  dsn: 'https://1aa524fcca5b4159f081d0b48cfe482e@mon.loco.ltd/6',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['tipa.ai']
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.8, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// bootstrap-vue: start
Vue.use(AlertPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(BadgePlugin)

Vue.use(TabsPlugin)
Vue.use(AvatarPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormTextareaPlugin)
Vue.use(InputGroupPlugin)

Vue.use(FormRadioPlugin)
Vue.use(SkeletonPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(SidebarPlugin)
Vue.use(OverlayPlugin)
Vue.use(FormSelectPlugin)
Vue.use(PaginationPlugin)
Vue.use(ProgressPlugin)

Vue.use(SpinnerPlugin)
Vue.use(ImagePlugin)
Vue.use(NavbarPlugin)
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(CardPlugin)
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
Vue.use(IconsPlugin)
// bootstrap-vue: end

Vue.use(MoneySpinner)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

Vue.prototype.message_maintenance = false
// Vue.prototype.message_maintenance = 'estamos em manutenção até as 14h30'

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
