import Vue from 'vue'

import IconTwitter from '@/assets/icons/twitter'
import IconTwitch from '@/assets/icons/twitch'
import IconTiktok from '@/assets/icons/tiktok'
import IconInstagram from '@/assets/icons/instagram'
import IconFacebook from '@/assets/icons/facebook'
import IconYoutube from '@/assets/icons/youtube'
import IconBooyah from '@/assets/icons/booyah'
import IconTrovo from '@/assets/icons/trovo'
import IconSite from '@/assets/icons/site'
import IconUpload from '@/assets/icons/upload'
import IconCompactUp from '@/assets/icons/compact-up'
import IconCompactDown from '@/assets/icons/compact-down'
import IconEyeSlash from '@/assets/icons/eye-slash'
import IconEye from '@/assets/icons/eye'
import IconTipa from '@/assets/icons/tipa'
import IconTrash from '@/assets/icons/trash'
import IconQrCode from '@/assets/icons/qr-code'
import IconBell from '@/assets/icons/bell'
import IconBellFill from '@/assets/icons/bell-fill'
import IconWallet from '@/assets/icons/wallet'
import IconArrowClockwise from '@/assets/icons/arrow-clockwise'

Vue.component('icon-twitter', IconTwitter)
Vue.component('icon-twitch', IconTwitch)
Vue.component('icon-tiktok', IconTiktok)
Vue.component('icon-instagram', IconInstagram)
Vue.component('icon-facebook', IconFacebook)
Vue.component('icon-youtube', IconYoutube)
Vue.component('icon-booyah', IconBooyah)
Vue.component('icon-trovo', IconTrovo)
Vue.component('icon-site', IconSite)
Vue.component('icon-upload', IconUpload)
Vue.component('icon-compact-up', IconCompactUp)
Vue.component('icon-compact-down', IconCompactDown)
Vue.component('icon-eye-slash', IconEyeSlash)
Vue.component('icon-eye', IconEye)
Vue.component('icon-tipa', IconTipa)
Vue.component('icon-trash', IconTrash)
Vue.component('icon-qr-code', IconQrCode)
Vue.component('icon-bell', IconBell)
Vue.component('icon-bell-fill', IconBellFill)
Vue.component('icon-wallet', IconWallet)
Vue.component('icon-arrow-clockwise', IconArrowClockwise)
