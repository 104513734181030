import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMultiTabState from 'vuex-multi-tab-state'

import user from './modules/user'
import modal from './modules/modal'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    modal
  },
  plugins: [createPersistedState({
    key: 'pixbrlive',
    paths: ['user']
  }), createMultiTabState()]
})
