<template>
  <div>
    <p class="mb-0">Precisamos de mais algumas informações para você começar a receber pela Tipa</p>

    <b-form class="form" @submit.prevent="onSubmit" autocomplete="off">
      <div v-if="session === 'initial'">
        <div class="row mb-2">
          <div class="col-12">
            <div class="col-meta mb-4">
              <small class="text-muted">
                <strong>
                  <a href="/pages/faq#dados-pessoais" class="text-muted" target="_blank">Ambiente seguro</a>
                </strong>
              </small>
            </div>
          </div>

          <div class="col-12">
            <div class="h-100 form-block-container">
              <b-form-group label="CPF/CNPJ">
                <b-form-input
                  v-model="form.document"
                  autocomplete="document"
                  maxlength="14"
                  type="text"
                  placeholder="sem pontos ou traços, apenas números"
                  required
                />
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12">
            <div class="h-100 form-block-container">
              <b-form-group label="Chave Pix para você receber" description="chave email, CPF ou aleatória - apenas a Tipa irá ver">
                <b-form-input
                  v-model="form.pix_key"
                  maxlength="78"
                  type="text"
                  placeholder="dê preferência por chave email ou CPF"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="session == 'social-media'">
        <p>Adicione ao menos uma rede social</p>

        <div class="d-flex justify-content-between mb-3">
          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.twitch" button>
            <icon-twitch fill_color="#fff" />
          </b-form-checkbox>

          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.twitter" button>
            <icon-twitter fill_color="#fff" />
          </b-form-checkbox>

          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.tiktok" button>
            <icon-tiktok fill_color="#fff" />
          </b-form-checkbox>

          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.instagram" button>
            <icon-instagram fill_color="#fff" />
          </b-form-checkbox>

          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.facebook" button>
            <icon-facebook fill_color="#fff" />
          </b-form-checkbox>

          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.youtube" button>
            <icon-youtube fill_color="#fff" />
          </b-form-checkbox>

          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.booyah" button>
            <icon-booyah fill_color="#fff" />
          </b-form-checkbox>

          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.trovo" button>
            <icon-trovo fill_color="#fff" />
          </b-form-checkbox>

          <b-form-checkbox class="socialEnabled-button" v-model="socialEnabled.site" button>
            <icon-site fill_color="#fff"/>
          </b-form-checkbox>
        </div>

        <b-form-group label-cols="2" label="Twitch" v-if="socialEnabled.twitch">
          <b-form-input
            v-model="form.twitch_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://twitch.tv/username"
          />
        </b-form-group>

        <b-form-group label-cols="2" label="Twitter" v-if="socialEnabled.twitter">
          <b-form-input
            v-model="form.twitter_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://twitter.com/username"
          />
        </b-form-group>

        <b-form-group label-cols="2" label="TikTok" v-if="socialEnabled.tiktok">
          <b-form-input
            v-model="form.tiktok_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://tiktok.com/username"
          />
        </b-form-group>

        <b-form-group label-cols="2" label="Instagram" v-if="socialEnabled.instagram">
          <b-form-input
            v-model="form.instagram_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://instagram.com/username"
          />
        </b-form-group>

        <b-form-group label-cols="2" label="Facebook" v-if="socialEnabled.facebook">
          <b-form-input
            v-model="form.facebook_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://facebook.com/username"
          />
        </b-form-group>

        <b-form-group label-cols="2" label="Youtube" v-if="socialEnabled.youtube">
          <b-form-input
            v-model="form.youtube_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://www.youtube.com/channel/UCk5BcU1rOy6hepflk7_q_Pw"
          />
        </b-form-group>

        <b-form-group label-cols="2" label="Booyah!" v-if="socialEnabled.booyah">
          <b-form-input
            v-model="form.booyah_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://booyah.live/username"
          />
        </b-form-group>

        <b-form-group label-cols="2" label="Trovo" v-if="socialEnabled.trovo">
          <b-form-input
            v-model="form.trovo_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://trovo.live/username"
          />
        </b-form-group>

        <b-form-group label-cols="2" label="Seu site" v-if="socialEnabled.site">
          <b-form-input
            v-model="form.site_url"
            maxlength="120"
            type="text"
            placeholder="ex: https://seusite.com"
          />
        </b-form-group>
      </div>

      <div v-else>
        <div class="col-12 mb-3">
          <legend class="mt-3 col-form-label">Adicione um avatar</legend>
          <div class="input-avatar">
            <!-- <b-avatar class="avatar-icon" :src="avatarUrl" /> -->
            <b-button class="w-100 btn-upload text-center" @click.stop.prevent="showAvatarCropper = true">
              <icon-upload />
              <span> Adicione um avatar</span>
            </b-button>
          </div>

          <avatar-cropper
            v-model="showAvatarCropper"
            :inline="false"
            @cancel="showAvatarCropper = false"
            @error="showAvatarCropper = false"
            :upload-url="avatarUploadPath"
            upload-form-name="avatar"
            :labels="cropperOptions.labels"
            :request-options="avatarUploadHeaders"
            :cropper-options="{
              movable: false,
              zoomable: true,
              rotatable: true,
              scalable: true,
              minCropBoxWidth: 150,
              maxCropBoxWidth: 350,
              aspectRatio: 1,
              autoCropArea: 1,
              viewMode: 3,
            }"
            @uploaded="handleUploadedAvatar"
          />
        </div>

        <div class="col-12">
          <div class="h-100 form-block-container">
            <b-form-group label="Nome de exibição no Perfil">
              <b-form-input
                v-model="form.nickname"
                maxlength="120"
                type="text"
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 actions my-3">
          <b-button class="mr-2" variant="outline-primary" @click="backSession()" :disabled="session == 'initial'">Voltar</b-button>
          <b-button variant="primary" type="submit">{{session !== 'avatar' ? "Continuar" : "Concluir" }}</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import AvatarCropper from 'vue-avatar-cropper'

import UserService from '@/services/user.service'

export default {
  name: 'formCreator',
  data: () => ({
    session: 'initial',
    form: {
      document: '',
      pix_key: '',
      twitch_url: '',
      twitter_url: '',
      tiktok_url: '',
      instagram_url: '',
      facebook_url: '',
      youtube_url: '',
      booyah_url: '',
      trovo_url: '',
      site_url: '',
      nickname: ''
    },
    socialEnabled: {
      twitch: false,
      twitter: false,
      tiktok: false,
      instagram: false,
      facebook: false,
      youtube: false,
      booyah: false,
      trovo: false,
      site: false
    },
    showAvatarCropper: false,
    showCoverCropper: false,
    cropperOptions: {
      labels: {
        submit: 'Salvar',
        cancel: 'Cancelar'
      }
    }
  }),
  components: {
    AvatarCropper
  },
  computed: {
    avatarUploadHeaders () {
      const token = this.$store.getters['user/token']
      console.log(token)
      return { method: 'POST', headers: { Authorization: token, Accept: 'application/json' } }
    },
    avatarUploadPath () {
      return UserService.avatarUploadPath()
    }
  },
  methods: {
    async handleUploadedAvatar (req) {
      this.showAvatarCropper = false

      if (req.response.status === 200) {
        req.response.json().then(data => {
          this.form.avatar_url = data.url
          this.$store.commit('user/updateAvatar', data.url)
        })
      }
    },
    backSession () {
      if (this.session === 'social-media') {
        this.session = 'initial'
      } else {
        this.session = 'social-media'
      }
    },
    async onSubmit () {
      try {
        if (this.session === 'initial') {
          this.session = 'social-media'
          this.$emit('socialMediaSession')
          return
        } else if (this.session === 'social-media') {
          this.session = 'avatar'
          this.$emit('avatarSession')
          return
        }

        if (this.form.twitch_url === '' &&
            this.form.twitter_url === '' &&
            this.form.tiktok_url === '' &&
            this.form.instagram_url === '' &&
            this.form.facebook_url === '' &&
            this.form.youtube_url === '' &&
            this.form.booyah_url === '' &&
            this.form.trovo_url === '' &&
            this.form.site_url === ''
        ) {
          this.$toasted.error('Informe ao menos uma rede social')
          return
        }

        const response = await UserService.updateProfile(this.form)

        if (response) {
          await this.$store.commit('user/updateCurrentUser')

          this.$emit('finishCreator')
        } else {
          console.log('Erro aqui')
        }
      } catch (err) {
        console.log(err)
        if (err.errors.length > 0) {
          err.errors.map((error) => this.$toasted.error(error))
        }
      }
    }
  }
}
</script>

<style lang="scss">
.socialEnabled-button {
  width: 32px;
  height: 32px;

  label {
    padding: 6px;
    margin: 0;
    width: 40px;
    height: 40px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}
</style>
