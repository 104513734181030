<script>

export default {
  name: 'SupportWidget',
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    }
  },
  mounted () {
    this.initializeIntercom()
  },
  methods: {
    initializeIntercom () {
      const APP_ID = 'ngspwgic' // IMPORTANT: Replace with your workspace ID
      const secretKey = 'bhknzdMjCGnFTMk_hnMJ2BnJTY8Cd-w4Bw6IO_Y-' // IMPORTANT: your web Identity Verification secret key - keep it safe!
      const userIdentifierEmail = this.currentUser?.email // IMPORTANT: the email for your user
      const userIdentifierName = this.currentUser?.name // IMPORTANT: the name for your user
      // Configurações do Intercom
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: APP_ID,
        name: userIdentifierName, // Substitua com a variável apropriada
        email: userIdentifierEmail, // Substitua com a variável apropriada
        user_hash: secretKey // IMPORTANTE: substitua isso pelo código HMAC que você gerou em seu servidor
      };

      // Inicializa o widget do Intercom
      (function () {
        var w = window
        var ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          var d = document
          var i = function () { i.c(arguments) }
          i.q = []
          i.c = function (args) { i.q.push(args) }
          w.Intercom = i
          var l = function () {
            var s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/' + APP_ID
            var x = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }
          if (document.readyState === 'complete') {
            l()
          } else if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })()
    }
  }
}
</script>
