<template>
  <b-modal
    v-model="show"
    centered
    hide-footer
    hide-header
    size="lg"
    id="modal-sign-in"
    class="modal-sign-in p-0"
    scrollable
    no-close-on-backdrop
  >
    <!-- <button class="button-close position-absolute close">×</button> -->
    <div class="row m-0 mb-md-1">
      <div class="col-12 col-lg-12 p-3 p-md-5">
        <h5 class="mb-3">Por motivos de segurança, seu acesso foi desativado temporariamente</h5>

        <div v-if="!informedAddress">
          <p class="mb-3">nossos sistemas detectaram irregularidades em sua conta, que está em análise por nosso time.
              para agilizar o procedimento, preencha o formulário abaixo</p>

          <b-form @submit.stop.prevent="onAddressSubmit">
            <div class="row">
              <div class="col-12">
                <b-form-group label="CEP" :disabled="zipcodeLoading">
                  <b-form-input type="number" maxlength="8" v-model="form_address.address_zipcode" />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group label="Endereço" :disabled="zipcodeLoading">
                  <b-form-input type="text" maxlength="120" v-model="form_address.address_street" />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <b-form-group label="Número">
                  <b-form-input ref="numberField" maxlength="50" type="text" v-model="form_address.address_number" />
                </b-form-group>
              </div>
              <div class="col-8">
                <b-form-group label="Complemento">
                  <b-form-input type="text" maxlength="100" v-model="form_address.address_complement" />
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <b-form-group label="Cidade" :disabled="zipcodeLoading">
                  <b-form-input type="text" maxlength="100" v-model="form_address.address_city" />
                </b-form-group>
              </div>

              <div class="col-4">
                <b-form-group label="Estado" :disabled="zipcodeLoading">
                  <b-form-input type="text" maxlength="2" v-model="form_address.address_state" />
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group label="Data de Nascimento">
                  <b-form-input type="date" maxlength="10" v-model="form_address.birthdate" />
                </b-form-group>
              </div>
            </div>

            <b-button block type="submit">Salvar e prosseguir</b-button>
          </b-form>
        </div>

        <div v-else>
          <p class="mb-3">obrigado pelos dados informados, sua conta está em análise</p>
        </div>

      </div>
    </div>

    <div class="row m-0 mb-md-1">
      <div class="col-12 col-lg-12 px-3 px-md-5 py-2 d-flex align-items-center">
        <small class="m-0 text-muted">Ficou com alguma dúvida?</small>
        <small>
          <a class="m-0 ml-1" href="https://bit.ly/tipadiscord">Estamos no discord esperando vc <span style="color: var(--brand-orange)">&lt;3</span></a>
        </small>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PremiumSubService from '@/services/premiumSub.service.js'

export default {
  name: 'ModalLocked',
  data () {
    return {
      form_address: {
        address_street: '',
        address_neighborhood: '',
        address_city: '',
        address_state: '',
        address_zipcode: '',
        birthdate: ''
      },
      zipcodeLoading: false,
      cardSubLoading: false,
      addressLoading: false
    }
  },
  components: {},
  watch: {
    'form_address.address_zipcode': function (newVal) {
      if (newVal && newVal.length === 8) {
        this.lookup()
      }
      this.form_address.address_zipcode = newVal.replace(/\D/g, '')
    }
  },
  computed: {
    show: {
      cache: false,
      get: function () {
        return this.$store.state.modal.show === 'modal-locked'
      },
      set: function () {
        this.$store.commit('modal/closeModal')
      }
    },
    informedAddress () {
      return this.$store.state.modal.informed_address
    },
    currentUser () {
      return this.$store.getters['user/currentUser']
    }
  },
  mounted () {
    this.saveEvent('open')
    // saveEvent
  },
  methods: {
    async lookup () {
      try {
        this.zipcodeLoading = true
        const resultZip = await PremiumSubService.zipLookup(this.form_address.address_zipcode)

        // this.form_address.address_street = resultZip.data.address
        // this.form_address.address_neighborhood = resultZip.data.district
        // this.form_address.address_city = resultZip.data.city
        // this.form_address.address_state = resultZip.data.state
        this.form_address.address_street = resultZip.data.logradouro
        this.form_address.address_neighborhood = resultZip.data.bairro
        this.form_address.address_city = resultZip.data.localidade
        this.form_address.address_state = resultZip.data.uf

        this.zipcodeLoading = false

        this.$refs.numberField.focus()
      } catch (_) {
        this.$toasted.error('Não encontramos o CEP informado')
        this.zipcodeLoading = false
      }
    },
    async onAddressSubmit () {
      try {
        this.addressLoading = true
        await PremiumSubService.updateAddress({ user: this.form_address })

        await this.$store.commit('user/updateCurrentUser')

        this.$toasted.success('Informações enviadas')
      } catch (_) {
        this.addressLoading = false
        this.$toasted.error(_.error)
      }
    }
  }
}
</script>

<style lang="scss">
  .card-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  #modal-sign-in {
    padding-left: 0px !important;
    .button-close {
      z-index: 999;
      position: absolute;
      right: 10px;
      top: 10px;

      color: var(--text-color)
    }
    h5, h6 {
      font-weight: 400
    }

    .modal-header {
      border-bottom: none;
    }
    .modal-body {
      overflow-y: scroll;
      padding: 0;
    }

    .modal-body::-webkit-scrollbar {
      display: none;
    }

    .modal-content {
      border: none;
    }
  }
</style>
