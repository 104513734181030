<template>
  <div id="app">
    <b-alert variant="info" :show="!!message_maintenance">
      <div class="container">
        <p class="mb-0 text-center">{{message_maintenance}}</p>
      </div>
    </b-alert>
    <!-- name="fade" mode="out-in" -->
    <component :is="layout">
      <transition
        class="card-transition"
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </component>

    <modal-sign-in />
    <modal-sign-up />
    <modal-premium />
    <modal-locked />
  </div>
</template>

<script>
import ModalSignIn from '@/components/ModalSignIn'
import ModalSignUp from '@/components/ModalSignUp'
import ModalPremium from '@/components/ModalPremium'
import ModalLocked from '@/components/ModalLocked'

const defaultLayout = 'overlay'

export default {
  name: 'App',
  metaInfo: {
    title: 'Receba donates por Pix em sua live na Twitch / Youtube / Booyah / Trovo',
    titleTemplate: '%s | tipa.ai',
    htmlAttrs: {
      lang: 'pt-BR', // en
      amp: false
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    ModalSignIn,
    ModalSignUp,
    ModalPremium,
    ModalLocked
  },
  data () {
    return {
      prevHeight: 0
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  mounted () {
    // this.$store.commit('modal/showModal', 'modal-premium')
  },
  methods: {
    beforeLeave (element) {
      this.prevHeight = getComputedStyle(element).height
    },
    enter (element) {
      const { height } = getComputedStyle(element)
      element.style.height = this.prevHeight
      setTimeout(() => {
        element.style.height = height
      })
    },
    afterEnter (element) {
      element.style.height = 'auto'
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
