<template>
  <b-modal
    v-model="show"
    centered
    hide-footer
    hide-header
    size="xl"
    id="modal-sign-in"
    class="modal-sign-in p-0"
    scrollable
  >
    <button class="button-close position-absolute close" @click="show = false">×</button>
    <div class="row m-0 mb-md-1">
      <b-form class="form col-12 col-lg-6 p-3 p-md-5" @submit.prevent="onSubmit" autocomplete="off">
        <h5 class="mb-3 text-muted">Já é cadastrado? Acesse sua conta</h5>
        <b-form-group label="Email">
          <b-form-input
            v-model="form.email"
            type="email"
            autocomplete="email"
            maxlength="120"
            required
          />
        </b-form-group>

        <b-form-group label="Senha">
          <b-form-input
            v-model="form.password"
            type="password"
            autocomplete="password"
            required
          />
        </b-form-group>

        <div class="card-buttons">
          <div>
            <b-btn variant="primary" type="submit">
              Log in
            </b-btn>
          </div>
        </div>

        <hr>

        <div class="mb-4">
          <h5 class="m-0 mb-1 text-muted">Não tem cadastro na Tipa?</h5>
          <p class="m-0 text-muted">venha e faça parte agora - é rapidinho e grátis 🧡</p>
        </div>
        <b-btn text @click.stop.prevent="openModalSignUp()">Cadastre-se</b-btn>
      </b-form>

      <div class="col-12 col-lg-6 p-3 p-md-5" style="background: rgba(125, 125, 125, 0.07)">
        <h5 class="mb-3 text-muted">Com sua conta na Tipa</h5>

        <h6>Você que é Apoiador:</h6>

        <ul class="list-style mt-2 px-4">
          <li class="list-item">
            <p class="mb-1">Apoie com valores mais altos</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Conecte com seus criadores favoritos</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Acesso ao histórico de doações</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Integração com comunidades via discord</p>
          </li>
        </ul>

        <h6>Você que é Criador:</h6>

        <ul class="list-style mt-2 px-4">
          <li class="list-item">
            <p class="mb-1">Receba apoio por Pix</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Monetize e aumente sua base de fãs</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Ganhe mais do que você ganha hoje - a tipa tem a menor taxa do mercado</p>
          </li>
          <li class="list-item">
            <p class="mb-1">Novos recursos todas as semanas</p>
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<script>
import UserService from '@/services/user.service.js'

export default {
  name: 'ModalSignIn',
  data () {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    show: {
      cache: false,
      get: function () {
        return this.$store.state.modal.show === 'modal-sign-in'
      },
      set: function () {
        this.$store.commit('modal/closeModal')
      }
    }
  },
  methods: {
    async onSubmit () {
      try {
        this.form.email = this.form.email.toLowerCase()

        const response = await UserService.login(this.form)

        this.$store.dispatch('user/login', response)

        this.show = false
      } catch (error) {
        this.$toasted.error('email/senha invalidos ou email não cadastrado')
      }
    },
    openModalSignUp () {
      this.$store.commit('modal/showModal', 'modal-sign-up')
    }
  }
}
</script>

<style lang="scss">
  .card-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  #modal-sign-in {
    padding-left: 0px !important;
    .button-close {
      z-index: 999;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    h5 {
      font-weight: 400
    }

    // h5 {
    //   font-weight: 500;
    // }
    .modal-header {
      border-bottom: none;
    }
    .modal-body {
      overflow-y: scroll;
      padding: 0;
    }

    .modal-body::-webkit-scrollbar {
      display: none;
    }

    .modal-content {
      border: none;
    }
  }
</style>
