import instance from './base.service'

class PremiumSubService {
  getAll () {
    return instance.get('premium_subs.json')
  }

  find (id) {
    return instance.get(`premium_subs/${id}.json`)
  }

  // update (id, params) {
  //   return instance.patch(`plugin_donation_goals/${id}.json`, { plugin_donation_goal: params })
  // }

  create () {
    return instance.get('premium_subs/new.json')
  }

  createNew (params) {
    return instance.post('premium_subs.json', params)
  }

  validateCoupon (discountCode) {
    return instance.get('premium_subs/validate_coupon.json?discount_code=' + discountCode)
  }

  zipLookup (zipcode) {
    return instance.get(`https://viacep.com.br/ws/${zipcode}/json/`)
  }

  zipLookupApiCep (zipcode) {
    return instance.get('https://ws.apicep.com/cep.json?code=' + zipcode)
  }

  updateAddress (params) {
    return instance.post('premium_subs/address.json', params)
  }

  // eslint-disable-next-line camelcase
  createSubCard (card_mask, payment_token) {
    return instance.post('premium_subs/card_sub.json', { card_mask, payment_token })
  }

  createWalletSub (id) {
    return instance.post(`premium_subs/${id}/wallet_sub.json`)
  }
}

export default new PremiumSubService()
