import axios from 'axios'
import base from './base.service.js'

// import Vue from 'vue'

import io from 'socket.io-client'

class AuthService {
  login (user) {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    // instance.interceptors.response.use(response => {
    //   return response
    // }, function (error) {
    //   try {
    //     if (error.response.status === 429) {
    //       Vue.toasted.info('Por questão de segurança sua conta foi bloqueada, tente novamente em 15 minutos')

    //       return
    //     }

    //     return error
    //   } catch (error) {
    //     return Promise.reject(error)
    //   }
    // })

    return instance.post(process.env.VUE_APP_API_URL + 'users/sign_in.json', { user })
  }

  create (params) {
    return base.post(process.env.VUE_APP_API_URL + 'users.json', params)
  }

  account () {
    return base.get('users/edit.json')
  }

  updateAccount (params) {
    return base.patch('users.json', { user: params })
  }

  updateProfile (params) {
    return base.put('api/users/update_profile', { user: params })
  }

  updateUsername (params) {
    return base.post('premium_subs/change_username_execute.json', { user: params })
  }

  updateOverlay (params) {
    return base.post('premium_subs/overlay_execute.json', { user: params })
  }

  updateScrapbook (params) {
    return base.post('premium_subs/scrapbook.json', { user: params })
  }

  updateSub (params) {
    return base.post('premium_subs/tipa_sub.json', { user: params })
  }

  updatePreferences (params) {
    return base.post('premium_subs/preferences.json', { user: params })
  }

  avatarUploadPath () {
    return process.env.VUE_APP_API_URL + 'api/avatar.json'
  }

  coverUploadPath () {
    return process.env.VUE_APP_API_URL + 'api/cover.json'
  }

  coverUpload (params) {
    const formData = new FormData()

    formData.append('file', params)
    return base.post(process.env.VUE_APP_API_URL + 'api/cover.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  soundUpload (params) {
    const formData = new FormData()

    formData.append('file', params)
    return base.post(process.env.VUE_APP_API_URL + 'api/sound.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  soundAlertUpload (params) {
    const formData = new FormData()

    formData.append('file', params)
    return base.post(process.env.VUE_APP_API_URL + 'api/sound_alert.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  gifUpload (params) {
    const formData = new FormData()

    formData.append('file', params)
    return base.post(process.env.VUE_APP_API_URL + 'api/gif.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  imageUpload (params) {
    const formData = new FormData()

    formData.append('file', params)
    return base.post(process.env.VUE_APP_API_URL + 'api/image.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  // for overlays and user
  uploadGalleryImage (params) {
    const formData = new FormData()

    formData.append('file', params)
    return base.post(process.env.VUE_APP_API_URL + 'api/overlays/image.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  uploadGallerySound (params) {
    const formData = new FormData()

    formData.append('file', params)
    return base.post(process.env.VUE_APP_API_URL + 'api/overlays/sound.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  updateTipping (params) {
    return base.post('premium_subs/tipping_execute.json', { user: params })
  }

  refreshOverlayToken () {
    return base.get('digital_accounts/refresh_overlay_token.json')
  }

  removeImage (imageType) {
    return base.get(`pictures/${imageType}.json`)
  }

  getDashboard (params = '') {
    return base.get(`api/dashboard.json${params}`)
  }

  getDashboardSubs (params = '') {
    return base.get(`api/dashboard_subs.json${params}`)
  }

  enqueueAlert (tipId) {
    return base.get(`api/v2/activities/${tipId}/enqueue.json`)
  }

  skipAlert () {
    return base.get('api/v2/activities/skip_alert.json')
  }

  skipAlertElements (jwt) {
    return new Promise((resolve, reject) => {
      const socket = io('https://realtime.streamelements.com', {
        transports: ['websocket']
      })
      socket.on('connect', onConnect)
      socket.on('authenticated', onAuthenticated)
      socket.on('disconnect', onDisconnected)
      socket.on('unauthorized', onError)
      function onConnect () {
        // console.log('Successfully connected to the websocket')
        // socket.emit('authenticate', { method: 'oauth2', token: accessToken })
        socket.emit('authenticate', { method: 'jwt', token: jwt })
      }

      function onDisconnected () {
        resolve('disconnected')
      }

      function onError (err) {
        reject(err)
      }

      function onAuthenticated (_data) {
        socket.emit('event:skip')

        setTimeout(() => (socket.disconnect()), 1000)
      }
    })
  }

  enqueueAlertAlertbox (type) {
    return base.get(`api/v2/activities/enqueue_test_alertbox.json?type=${type}`)
  }

  enqueueTestAlert (configId) {
    return base.get('users/enqueue_test_alert.json?user_widget_id=' + configId)
  }

  resendConfirmation (user) {
    return base.post('users/confirmation.json', { user })
  }

  confirmation (token) {
    return base.get(`users/confirmation.json?confirmation_token=${token}`)
  }

  // register (user) {
  //   return axios.post(process.env.VUE_APP_API_URL + 'api/users.json', user)
  // }

  forgotPassword (user) {
    return base.post('users/password.json', {
      user
    })
  }

  logout () {
    return base.get(process.env.VUE_APP_API_URL + 'users/sign_out.json')
  }

  resetPassword (user) {
    return base.put('users/password.json', {
      user
    })
  }

  resendUnlock (user) {
    return base.post('users/unlock.json', { user })
  }

  unlock (token) {
    return base.get(`users/unlock.json?unlock_token=${token}`)
  }

  // confirm (token) {
  //   return axios.put(`${process.env.VUE_APP_API_URL}confirmations/${token.token}.json`, {}).then(response => {
  //     return response.data
  //   })
  // }

  find (id, ranking = '') {
    return base.get(`users/${id}.json?ranking=${ranking}`)
  }

  findScrapbook (id, params = '') {
    return base.get(`users/${id}/scrapbook.json${params}`)
  }

  findActivity (id, params = '') {
    return base.get(`users/${id}/activity.json${params}`)
  }

  tipUser (id, params) {
    // audio_blob
    // audio_record_time
    // audio_mime_type

    // console.log(params)
    if (params.audio_blob && params.audio_mime_type && params.audio_record_time > 0) {
      // console.log('é áudio')
      // console.log(params.audio_mime_type)

      const ext = params.audio_mime_type.indexOf('mp4') > -1 ? 'mp4' : 'webm'

      const formData = new FormData()
      formData.append('audio_file', params.audio_blob, 'audio.' + ext)
      formData.append('audio_record_time', params.audio_record_time)
      formData.append('audio_mime_type', params.audio_mime_type)

      // append to formData all the other params
      for (const key in params) {
        if (key !== 'audio_blob' && key !== 'audio_record_time' && key !== 'audio_mime_type') {
          formData.append(key, params[key])
        }
      }

      return base.post(`users/${id}/tip_audio.json`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      // return base.post(`users/${id}/tip_audio.json`, { tip: params })
    } else {
      return base.post(`users/${id}/tip.json`, { tip: params })
    }
  }

  scrapUser (id, params) {
    return base.post(`users/${id}/tip.json`, { tip: params })
  }

  subUser (id, params) {
    return base.post(`users/${id}/tipa_sub.json`, { tipa_sub: params })
  }

  findSub (id) {
    return base.get(`tipa_subs/${id}.json`)
  }

  findSubs (id, params = '') {
    return base.get(`users/${id}/subs.json${params}`)
  }

  health () {
    return base.get('health.json')
  }

  gifSearch (q, offset = 1) {
    return base.get(`api/v1/gif_search.json?q=${q}&offset=${offset}`)
  }

  connectWithTwitch (redirectTo = '') {
    return base.get(`api/v2/twitch/connect.json?redirectTo=${redirectTo}`)
  }

  connectWithTrovo () {
    return base.get('api/trovo/connect.json')
  }

  connectWithStreamlabs () {
    return base.get('api/v2/streamlabs/connect.json')
  }

  connectWithDiscord (addBot = false) {
    return base.get('api/v2/discord/connect.json' + (addBot ? '?addbot=true' : ''))
  }

  connectWithStreamelements (params) {
    return base.post('api/v2/streamelements/connect.json', { user: params })
  }

  getElements () {
    return base.get('api/v2/streamelements/me.json')
  }

  getWebhooks () {
    return base.get('api/v2/webhooks/me.json')
  }

  connectWithWebhooks (params) {
    return base.post('api/v2/webhooks/connect.json', { user: params })
  }

  refreshWebhooksToken () {
    return base.get('api/v2/webhooks/refresh_token.json')
  }

  getTwitchConfig () {
    return base.get('api/v2/customization/twitch.json')
  }

  updateTwitchConfig (params) {
    return base.put('api/v2/customization/twitch.json', { user: params })
  }

  updateVolume (params) {
    return base.post('premium_subs/alert_volumes.json', { user: params })
  }

  skipOnboarding (segment) {
    return base.post('api/v2/onboarding.json', {
      segment
    })
  }

  getInfoOnboarding () {
    return base.get('api/v2/onboarding.json')
  }

  notifications () {
    return base.get('api/notifications.json')
  }

  notificationsRead (id) {
    return base.get(`api/v2/user_notifications/read/${id}.json`)
  }

  notificationsReaction (id, _reaction) {
    return base.create('api/user_notifications.json', {
      notification_id: id,
      reaction: _reaction
    })
  }

  createNotification (id, reaction = null) {
    return base.post('api/user_notifications.json', {
      notification_id: id,
      reaction: reaction
    })
  }

  indexNotification () {
    return base.get('api/user_notifications.json')
  }
}

export default new AuthService()
