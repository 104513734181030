<template>
  <div>
    <div class="user-overlay">
      <transition>
        <slot />
      </transition>
    </div>
  </div>
</template>

<script>
// import UserService from '@/services/user.service'

export default {
  name: 'OverlayLayout',
  metaInfo: {
    bodyAttrs: {
      class: ['overlay-layout']
    }
  },
  components: {
  },
  computed: {
    // currentUser () {
    //   return this.$store.getters['user/currentUser']
    // },
    // isLoggedIn () {
    //   return this.$store.getters['user/loggedIn']
    // }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.user-overlay {
  color: white;
  font-weight: bold;

  text-shadow: -1px 0 black,
    0 1px black,
    1px 0 black,
    0 -1px black;

  font-family: sans;
}

.user-overlay p {
  font-size: 2rem;
  line-height: 125%;
  margin: 0.25rem;
}

.user-overlay span {
  color: yellow;
}
</style>
