<template>
  <div class="container py-4">
    <div class="py-5 mb-4 rounded-3 bg-logo splash">
      <div class="container-fluid text-dark py-5">

        <div class="logo-container">
          <lottie-animation
            path="logo.json"
            :loop="false"
            class="tipa-logo"
          ></lottie-animation>
        </div>

        <!-- <img src="@/assets/logo-watchdog-white.svg" style="width: 120px; margin-left: -20px;"> -->
        <h1 class="display-5 fw-bold">Opppsss, página não encontrada</h1>

        <div class="features">
          <h4 class="feature">
            Essa página que vc está tentando acessar não existe por aqui
          </h4>
        </div>

        <a class="btn btn-primary btn-lg" href="/">Voltar ao início</a>
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
export default {
  components: {
    LottieAnimation
  }
}
</script>
<style lang="scss" scoped>
.logo-container {
    margin-top: 0rem;
    width: 300px;
    .tipa-logo {
      height: 220px !important;
      transform: translateX(-20px);
    }
  }
  .container {
    height: calc(100vh - 50px);

    color: white;
    background-repeat:no-repeat;
    background-size: initial;
    background-position-x: 70%;

    h1 {
      margin-top: 4rem;
    }

    h4.feature {
      margin: 1rem 0;
      font-weight: 300;
    }

    .btn-primary {
      margin-top: 1rem;
    }
  }
</style>
