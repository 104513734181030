export default {
  namespaced: true,
  state () {
    return {
      show: '',
      balance: 0,
      informed_address: false
    }
  },
  mutations: {
    showModal (state, payload) {
      state.show = payload
    },
    closeModal (state) {
      state.show = ''
    },
    setBalance (state, payload) {
      state.balance = payload
    },
    setInformedAddress (state, payload) {
      state.informed_address = payload
    }
  }
}
