import axios from 'axios'
import store from '../store'
import router from '../router'
import Vue from 'vue'

// create a new axios instance
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

// before a request is made start the nprogress
instance.interceptors.request.use(config => {
  if (store.getters['user/loggedIn']) {
    config.headers.Authorization = `${store.getters['user/token']}`
  }

  return config
})

instance.interceptors.response.use(response => {
  return response
}, function (error) {
  try {
    if (!error.response && !['OverlayAlert', 'OverlayQrcode', 'OverlayGoal', 'OverlayLastTen', 'OverlayRanking', 'OverlayLatestDonator'].includes(router.history.current.name)) {
      router.push('/').then(() => {}).catch(() => {})
      Vue.toasted.info('Houston, we have a problem.<br><br>Tente novamente em instantes.<br>Se o problema persistir, contate o suporte')
      return
    }

    console.error(error)
    console.error('interceptor response error', error.response.status)

    switch (error.response.status) {
    case 500:
      Vue.toasted.error('Ocorreu algum problema interno: ' + JSON.stringify(error.response.data))
      break

    case 404:
      Vue.toasted.error('Esta rota não existe')
      break

    case 401:
      store.commit('modal/showModal', 'modal-sign-in')
      // Vue.toasted.error('Sua sessão expirou, por favor, faça login.')
      // store.commit('user/logout')

      try {
        // router.push({
        //   name: 'Login'
        // })
      } catch (_) {
        // already on page
      }
      break
    case 429:
      Vue.toasted.info('Por questão de segurança sua conta foi bloqueada, tente novamente em 15 minutos')

      break
    }

    return Promise.reject(error.response.data)

    //   case 302:
    //     Vue.toasted.error('Esta rota foi modificada');

    //   break;

    //   case 401:
    //     Vue.toasted.error('Você não tem permissão');

    //   break;

    //   case 406:
    //     Vue.toasted.error('hola billo');
    //   break;

    //   default:
    //     return Promise.reject(error);
    // }
  } catch (error) {
    return Promise.reject(error)
  }
})

export default instance
