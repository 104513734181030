<template>
  <div>
    <header-small />
    <div class="first-section">
      <div class="video-preview">
        <video autoplay muted playsinline loop width="100%">
          <!-- <source src="/videos-site/videopreviewfundo.mp4" type="video/mp4"> -->
          <source src="/videos-site/bgvideo.webm" type="video/webm">
          <source src="/videos-site/bgvideo.mp4" type="video/mp4">
        </video>
      </div>
      <div class="container">
        <div class="row">

          <div class="col-12 col-md-6 content-area">
            <slot />

            <footer-links />
          </div>
        </div>
        <!-- <site-footer></site-footer> -->
      </div>

    </div>
  </div>
</template>

<script>
import HeaderSmall from '@/components/HeaderSmall/index.vue'
import FooterLinks from '@/components/FooterLinks/index.vue'
// import SiteFooter from '@/components/SiteFooter/index.vue'

export default {
  name: 'AuthLayout',
  metaInfo: {
    bodyAttrs: {
      class: ['auth-layout']
    }
  },
  components: {
    HeaderSmall,
    FooterLinks
    // SiteFooter
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 50px;
}

@keyframes AnimationName {
  0%{background-position:15% 0%}
  50%{background-position:86% 100%}
  100%{background-position:15% 0%}
}

@keyframes AppearText {
  0% {
    transform: translate3d(0px, 20px, 0px) scale3d(1, 1, 1) skew(0deg, 0deg);
    opacity: 0;
  }
  60% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) skew(0deg, 0deg);
    // transform-style: preserve-3d;
    opacity: .6;
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1;
  }
}

@keyframes AppearChevron {
  0% { opacity: 0; }
  100% {
    opacity: .55;
  }
}

.first-section {
  background: linear-gradient(270deg, rgba(#00bab3, .9), rgba(#00ffeb, .4), rgba(#00987e, .9));
  background-size: 600% 600%;

  animation: AnimationName 22s ease infinite;

  color: rgba(255, 255, 255, .9);

  position: relative;
  overflow: hidden;

  min-height: 100vh;

  .video-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    animation: AppearChevron 1s ease;
    animation-fill-mode: forwards;
    animation-delay: .7s;

    @media screen and (max-width: 1400px) {
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;

    .feature-users {
      animation: AppearText 1.1s ease-out; // ease-in-out infinite
      animation-fill-mode: forwards;
      opacity: 0;
      animation-delay: 1.2s;

      min-height: 160px;
      max-height: 300px;
      // padding: 8rem 0 4rem;

      h3 {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .main-text {
      padding-top: 8rem;

      h1 {
        font-size: 3.0rem;
        font-weight: 600;
        margin-bottom: 0rem;
        margin-top: 3rem;
        animation: AppearText 1.1s ease-out; // ease-in-out infinite
        opacity: 0;
        animation-delay: .2s;
        animation-fill-mode: forwards;
        color: rgba(255, 255, 255, .85);
      }
      h3 {
        font-weight: 400;
        animation: AppearText 1.1s ease-out; // ease-in-out infinite
        animation-fill-mode: forwards;
        opacity: 0;
        animation-delay: .6s;

        // strong {
        // }
      }

      .text-underlined {
        background-image: linear-gradient(0deg, transparent 10%, #eee 11%, #ddd 33%, transparent 34%);
        background-image: none;
      }

      @media screen and (max-width: 700px) {
        padding: 4rem 0 4rem;
        h1 {
          font-size: 3.4rem;
        }
        h3 {
          font-size: 1.2rem;
        }

      }
    }

    .btn {
      border-radius: 100px;
      font-size: 1.5rem;
      padding: .5rem 1.5rem;
      animation: AppearText 1.1s ease-out; // ease-in-out infinite
      animation-fill-mode: forwards;
      opacity: 0;
      animation-delay: 1.2s;

      // background: transparent;
      // border: 2px solid var(--brand-orange);
      // color: var(--brand-orange);
      // background: transparent;

      // border: 2px solid white;
      // color: var(--brand-orange);
    }

    .livestream-section {
      padding: 8rem 0 0;
    }
  }
}

</style>
